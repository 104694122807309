import { catchError } from 'rxjs/operators';
import { CuponsService } from './../../services/cupons.service';
import { PaymentService } from './../../services/payment.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { CheckboxModule } from 'primeng/checkbox';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { LoadingService } from '../../services/loading.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ValidatorComponent } from '../../components/validator/validator.component';
import { CustomerService } from '../../services/customer.service';
import { BackRouterComponent } from '../../components/back-router/back-router.component';
import { QRCodeModule } from 'angularx-qrcode';

interface Payment {
  name: string;
  value: number;
}

interface iuguAnswer {
  id: string;
  method: string;
  test: boolean;
  extra_info: {
    brand: string;
    bin: string;
    display_number: string;
    holder_name: string;
    month: number;
    year: number;
  };
}
@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    CommonModule,
    ButtonModule,
    CheckboxModule,
    PasswordModule,
    ReactiveFormsModule,
    InputMaskModule,
    InputNumberModule,
    InputTextModule,
    CardModule,
    RadioButtonModule,
    RouterLink,
    BackRouterComponent,
    QRCodeModule,
  ],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss',
})
export class PaymentComponent {
  payments = [
    { name: 'Cartão de crédito', value: 1 },
    { name: 'PIX', value: 2 },
  ];

  isCopied = false;

  parcelas: any[] = [];

  selectedPayment: any = null;
  selectedItem: any = [];
  cartaoCreditoForm!: FormGroup;
  isMobile: boolean = false;
  valorParcelaAtual: any;
  isDesktop: boolean;
  id: string | null | undefined;
  pixGerado: string = '';
  isCupom: boolean = false;
  valorCupom: number = 0;
  creditCardObject: iuguAnswer | undefined = undefined;
  selectedBrand: string = '';
  currentIcon: string = '';
  carrinho: any[] = [];
  subscriptions: any[] = [];
  cupons: any[] = [];
  valorTotalItens: number = 0;
  valorTotalCarrinho: number = 0;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private paymentService: PaymentService,
    private cuponsService: CuponsService,
    private customerService: CustomerService
  ) {
    this.createCartaoCreditoForm();
  }

  async ngOnInit() {
    this.loadingService.show();
    this.verifyCuponOrSubscription();
    if (typeof window !== 'undefined') {
      this.checkWidth();
      window.addEventListener('resize', () => {
        this.checkWidth();
      });
    }
    this.loadingService.hide();
  }

  async verifyCuponOrSubscription() {
    const url = window.location.href;
    if (url.includes('cupom')) {
      this.isCupom = true;
      this.getCuponsCarrinho();
      this.getCupons();
    } else {
      this.isCupom = false;
      this.payments = [{ name: 'Cartão de crédito', value: 1 }];
      this.subscriptions = await this.getAssinaturas();
      this.id = this.route.snapshot.paramMap.get('idAssinatura');
      const resp = this.subscriptions.find(
        (category) => category.id === this.id
      );
      this.selectedItem = resp;
      this.valorTotalItens = this.selectedItem.value;
    }
    this.mudarValorParcelasPorCategoria();
  }

  getCuponsCarrinho() {
    this.carrinho = JSON.parse(sessionStorage.getItem('carrinho') || '[]');
    this.id = this.carrinho[0]?.id;
    this.valorTotalCarrinho = this.carrinho.map((cupom) => cupom.value).reduce((a, b) => a + b, 0);
  }

  async getCupons() {
    const cupons = await this.getAllCupons();
    if (cupons) {
      this.carrinho.forEach((element) => {
        let cupom = cupons.find((cupom: any) => cupom.id === element.id);
        if (cupom) {
          cupom.quantity = element.quantity;
          this.cupons.push(cupom);
          this.valorTotalItens += cupom.value * element.quantity;
        }
      });
    }

  }

  isColorScreen() {
    if (!this.isMobile && this.selectedPayment === null) {
      return true;
    }
    return false;
  }

  createCartaoCreditoForm() {
    this.cartaoCreditoForm = this.fb.group({
      cpf: ['', [Validators.required]],
      cardNumber: ['', [Validators.required]],
      cvv: ['', [Validators.required]],
      cardExpiration: [
        '',
        { validators: ValidatorComponent.mmYYDate, updateOn: 'blur' },
      ],
      name: ['', [Validators.required]],
      parcelas: ['', [Validators.required]],
    });
  }

  convertToNumber(value: string): number {
    return Number(value);
  }

  mudarValorParcela(event: any) {
    this.valorParcelaAtual = this.parcelas.find(
      (parcela) => parcela.value === event.value
    )?.price;
    this.verifyCreditCardAndGetToken();
    this.changeBrandCard(this.creditCardObject?.extra_info?.brand || '');
  }

  getCardToken(event: any) {
    console.log(event);
  }

  verifyCreditCardAndGetToken() {
    if (this.selectedPayment === 1) {
      const cardNumber = this.cartaoCreditoForm.get('cardNumber')?.value;
      const cardNumberMask = cardNumber.replace(/\D/g, '');
      const cardExpiration =
        this.cartaoCreditoForm.get('cardExpiration')?.value;
      const cvv = this.cartaoCreditoForm.get('cvv')?.value;
      const name = this.cartaoCreditoForm.get('name')?.value;
      MobilusIugu.init(true);
      const data = MobilusIugu.createCreditCard(
        cardNumberMask,
        cardExpiration.split('/')[0],
        '20' + cardExpiration.split('/')[1],
        name.split(' ')[0],
        name.split(' ')[1],
        cvv
      );
      MobilusIugu.createPaymentToken(
        data,
        this.succesCallback,
        this.errorCallback
      );
    }
  }

  changeBrandCard(brand: string) {
    const prefix = '/assets/img/bandeiras-cartoes/';
    switch (brand) {
      case 'VISA':
        this.selectedBrand = 'VISA';
        this.currentIcon = prefix + 'bandeira_visa.svg';
        break;
      case 'MASTERCARD':
        this.selectedBrand = 'MASTERCARD';
        this.currentIcon = prefix + 'bandeira_mastercard.svg';
        break;
      case 'HIPERCARD':
        this.selectedBrand = 'VISA';
        this.currentIcon = prefix + 'bandeira_hipercard.svg';
        break;
      default:
        this.selectedBrand = 'VISA';
        this.currentIcon = prefix + 'bandeira_visa.svg';
        break;
    }
  }

  succesCallback = (data: any) => {
    this.creditCardObject = data;
  };
  errorCallback(data: any) {
    console.log('error', data);
  }

  createPaymentObject() {
    let paymentObject = {
      voucherList: [{ id: '', quantity: 0 }],
      providerId: null,
      status: 'Pendente',
      method: this.creditCardObject?.method,
      token: this.creditCardObject?.id,
      months: 1,
    };
    console.log("🚀 ~ PaymentComponent ~ createPaymentObject ~ paymentObject:", paymentObject)
    return this.setListCupons(paymentObject);
  }

  setListCupons(paymentObject: any) {
    console.log("🚀 ~ PaymentComponent ~ this.cupons.forEach ~ this.cupons:", this.cupons)
    this.cupons.forEach((cupom) => {
      paymentObject.voucherList.push({
        id: cupom.id,
        quantity: cupom.quantity,
      });
    });
    paymentObject.voucherList.shift();
    return paymentObject;
  }

  createPaymentObjectPix() {
    let paymentObject = {
      voucherList: [{ id: '', quantity: 0 }],
      providerId: null,
      status: 'Pendente',
      method: 'PIX',
      token: '',
      months: 1,
    };
    return this.setListCupons(paymentObject);
  }

  // gerarPix() {

  // }

  async submitPix() {
    try {
      this.loadingService.show();
      const paymentObject = this.createPaymentObjectPix();
      await this.paymentService
        .createPayment(paymentObject)
        .then((resp) => {
          this.notifySuceess(0);
          // sessionStorage.removeItem('carrinho');
          // this.router.navigate(['/meus-cupons']);
        })
        .catch((error: any) => {
          this.loadingService.notify('Erro ao processar pagamento');
          this.loadingService.hide();
        });
    } catch (error) {
      this.loadingService.notify('Erro ao processar pagamento');
      this.loadingService.hide();
    }
    this.loadingService.hide();
  }

  async submitForm() {
    try {
      this.loadingService.show();
      if (this.isCupom) {
        const paymentObject = this.createPaymentObject();
        await this.paymentService
          .createPayment(paymentObject)
          .then((resp) => {
            this.notifySuceess(1);
            sessionStorage.removeItem('carrinho');
            this.router.navigate(['/meus-cupons']);
          })
          .catch((error: any) => {
            this.loadingService.notify('Erro ao processar pagamento');
            this.loadingService.hide();
          });
      } else {
        await this.paymentService
          .registerCreditCard(this.creditCardObject?.id, {})
          .then(async (resp) => {
            let body = {
              subscriptionId: this.id,
            };
            await this.paymentService
              .createSubPayment(body)
              .then(async (resp) => {
                this.notifySuceess(1);
                const sub = await this.getSubs();
                sessionStorage.setItem('subscriptions', JSON.stringify(sub[0]));
                this.router.navigate(['/carteirinha']);
              })
              .catch((error: any) => {
                // Replace .catchError() with .catch()
                this.loadingService.notify('Erro ao processar pagamento');
                this.loadingService.hide();
              });
          });
      }
    } catch (error) {
      this.loadingService.notify('Erro ao processar pagamento');
      this.loadingService.hide();
    }
    this.loadingService.hide();
  }

  notifySuceess(type: number) {
    this.loadingService.notify('Pagamento realizado com sucesso');
    this.loadingService.hide();
    if (type === 0) {
      this.router.navigate(['/meus-cupons']);
    }
  }

  mudarValorParcelasPorCategoria() {
    this.parcelas = [];
    if (this.isCupom) {
      for (let i = 1; i <= 1; i++) {
        this.parcelas.push({
          value: i,
          price: this.valorTotalItens / i,
        });
      }
    } else {
      for (let i = 1; i <= 1; i++) {
        this.parcelas.push({
          value: i,
          price: this.selectedItem.value / i,
        });
      }
    }
  }

  async getSubs() {
    try {
      return await this.customerService.getSubscriptions();
    } catch (error) {
      this.loadingService.hide();
    }
  }

  async doPayment(body: any) {
    try {
      return await this.paymentService.createPayment(body);
    } catch (error) {
      this.loadingService.notify('Erro ao processar pagamento');
    }
  }

  formatValue(value: number) {
    if (value === undefined) {
      return 'R$ 0,00';
    }
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  checkWidth() {
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth < 768;
    }
  }

  async getAssinaturas() {
    try {
      return await this.paymentService.getSubscriptions();
    } catch (error) {
      console.log(error);
    }
  }

  async getNormalCupons() {
    try {
      return await this.cuponsService.getNormalCoupons();
    } catch (error) {
      console.log(error);
    }
  }

  async getAllCupons() {
    try {
      return await this.cuponsService.getAllActiveCupons();
    } catch (error) {
      console.log(error);
    }
  }

  copiarTexto() {
    const elementoParaCopiar = document.getElementById('copyDiv');
    const selecao = window.getSelection();
    const faixa = document.createRange();
    if (elementoParaCopiar) {
      faixa.selectNodeContents(elementoParaCopiar);
    }
    selecao?.removeAllRanges();
    selecao?.addRange(faixa);
    document.execCommand('copy');
    selecao?.removeAllRanges();
    this.isCopied = true;
  }

  checkIfMobile() {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 768;
    this.isDesktop = !this.isMobile;
  }
}
