<section class="exam-upload pt-8" style="overflow-x: hidden;">
  <div class="flex align-items-center justify-content-center px-3 flex-wrap mt-7">
    <a [routerLink]="['/quiosque-area']" class="mr-auto py-3 lg:py-0 flex gap-2">
      <i class="pi pi-chevron-circle-left"> </i>
      <span>Voltar</span>
    </a>

    <form [formGroup]="searchForm" (ngSubmit)="search()" class="flex gap-5 search-form mr-auto flex-wrap">
      <p-iconField iconPosition="left">
        <p-inputIcon styleClass="pi pi-search"></p-inputIcon>
        <input type="text" pInputText placeholder="Buscar por nome, CPF ou E-mail" formControlName="searchTerm" />
      </p-iconField>
      <p-button type="submit" label="Buscar" severity="success" class="w-full lg:w-auto"
        styleClass="p-button-login w-full lg:w-auto"></p-button>
      @if(searchForm.valid){
      <p-button (onClick)="limpar()" label="Limpar" severity="danger" class="w-full lg:w-auto"
        styleClass="p-button-login w-full lg:w-auto"></p-button>
      }
    </form>
  </div>
  <div class="gap-3 grid justify-content-center p-5">
    <div *ngFor="let item of customers.items; let i = index; trackBy: trackByIndex" class="col-12 md:col-6 lg:col-3">
      <div class="mr-3">
        <div class="bg-card-header text-center p-3">
          <div class="flex align-items-center surface-border py-2">
            <div class="round-black">
              <img src="/assets/img/icons/icon-plus.png" class="mx-3 my-1" style="width: 60px; height: 60px" alt="" />
            </div>
            <div class="flex flex-column align-items-start">
              <span class="text-xl text-white font-medium mb-1">{{ item.name }}</span>
              <span class="text-xs text-white font-medium mb-1 text-left">{{ item.email }}</span>
            </div>
          </div>
        </div>
        <div
          class="p-4 flex justify-content-center align-items-center bg-white flex-grow-1 shadow-2 border-round-bottom-3xl shadow-none border-none card-content">
          <p-button label="Ver Exames" severity="success" styleClass="p-button-login border-round-3xl w-full"
            [routerLink]="['/area-upload', item.id]"></p-button>
        </div>
      </div>
    </div>
  </div>

  <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
</section>
