import {
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpHandlerFn,
  HttpEvent,
} from '@angular/common/http';
import { throwError, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { LoadingService } from './services/loading.service';
import { inject } from '@angular/core';

const TOKEN_HEADER_KEY = 'authorization';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const loadingService = inject(LoadingService);
  const authService = inject(AuthService);
  let token = JSON.parse(authService.getToken());

  if (token) {
    req = req.clone({
      headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token.accessToken),
    });
  }

  if (JSON.parse(authService.getToken())?.refreshToken) {
    return next(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          const refresh = JSON.parse(authService.getToken()).refreshToken;
          if (refresh) {
            return authService.refreshToken(refresh).pipe(
              switchMap((refreshedToken: any) => {
                authService.setToken(JSON.stringify(refreshedToken));
                const cloned = req.clone({
                  headers: req.headers.set(
                    TOKEN_HEADER_KEY,
                    'Bearer ' + refreshedToken.accessToken
                  ),
                });
                return next(cloned);
              }),
              catchError((refreshError) => {
                console.error(
                  '🚀 ~ refreshToken catchError ~ error:',
                  refreshError
                );
                loadingService.hide();
                // Aqui você pode decidir redirecionar para o login ou mostrar uma mensagem
                return throwError(
                  () =>
                    new Error('Login expirado, por favor faça login novamente.')
                );
              })
            );
          } else {
            // Tratamento para quando não há refreshToken disponível
            loadingService.hide();
            return throwError(() => new Error('Login necessário.'));
          }
        } else {
          const err = error.error.message || error.statusText;
          loadingService.hide();
          return throwError(() => new Error(err));
        }
      })
    );
  } else {
    return next(req);
  }
};
