import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { PartnerService } from '../../services/partner.service';
import { LoadingService } from '../../services/loading.service';
import { Location } from '@angular/common';
import { DialogModule } from 'primeng/dialog';

interface Partner {
  id: string;
  name: string;
  normalizedName: string;
  urlImage: string;
  createdAt: string;
  updatedAt: string;
}

@Component({
  selector: 'app-partner',
  standalone: true,
  imports: [ButtonModule, CommonModule, RouterLink, DialogModule],
  templateUrl: './partner.component.html',
  styleUrl: './partner.component.scss',
})
export class PartnerComponent {
  isMobile: boolean = false;
  isDesktop: boolean;
  arrColors: string[] = ['#78CDD1', '#E4E6E7', '#78CDD1'];
  categoriaParceiros: any[] = [];
  displayedCategories: any[] = [];
  hasMoreCategories = false;
  dialog = false;

  constructor(
    private partnerService: PartnerService,
    private router: Router,
    private loadingService: LoadingService,
    private location: Location
  ) {}

  async ngOnInit() {
    this.loadingService.show();
    this.categoriaParceiros = await this.getCategorias();
    if (typeof window !== 'undefined') {
      this.checkWidth();
      window.addEventListener('resize', () => {
        this.checkWidth();
      });
    }
    this.displayedCategories = this.categoriaParceiros.slice(0, 4);
    this.hasMoreCategories = this.categoriaParceiros.length > 4;

    this.loadingService.hide();
  }

  showAllCategories() {
    this.displayedCategories = this.categoriaParceiros;
    this.hasMoreCategories = false;
  }

  async getCategorias() {
    try {
      return await this.partnerService.getCategoriasParceiros();
    } catch (error) {
      console.log(error);
    }
  }

  goToCategory(category: any) {
    const normalizedCategoryName = this.normalizeString(category.name);
    this.router.navigate(['/parceiros/' + normalizedCategoryName + '/' + category.id]);
  }

  normalizeString(str: string): string {
    const normalizedStr = str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    return normalizedStr;
  }

  checkWidth() {
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth < 768;
    }
  }
  checkIfMobile() {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 768;
    this.isDesktop = !this.isMobile;
  }

  goBack(): void {
    this.loadingService.goBack();
  }
}
