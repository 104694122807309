import { PartnerComponent } from './pages/partner/partner.component';
import { RegisterComponent } from './components/register/register.component';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { QuestionComponent } from './components/register/question/question.component';
import { AddressComponent } from './components/register/address/address.component';
import { IncomeComponent } from './components/register/income/income.component';
import { AnamneseComponent } from './components/register/anamnese/anamnese.component';
import { ClientAreaComponent } from './pages/client-area/client-area.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ResultsComponent } from './pages/client-area/results/results.component';
import { MyCouponComponent } from './pages/my-coupon/my-coupon.component';
import { DetalheMyCouponComponent } from './pages/my-coupon/detalhe-my-coupon/detalhe-my-coupon.component';
import { NgModule } from '@angular/core';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { CategoryPartnerComponent } from './pages/partner/category-partner/category-partner.component';
import { RedefinirSenhaComponent } from './components/redefinir-senha/redefinir-senha.component';
import { NovaSenhaComponent } from './components/redefinir-senha/nova-senha/nova-senha.component';
import { CarteirinhaComponent } from './pages/carteirinha/carteirinha.component';
import { CarrinhoComponent } from './pages/carrinho/carrinho.component';
import { CuponsComponent } from './pages/cupons/cupons.component';
import { DetalheCupomComponent } from './pages/cupons/detalhe-cupom/detalhe-cupom.component';
import { QuiosqueAreaComponent } from './pages/quiosque-area/quiosque-area.component';
import { RegisterQuiosqueComponent } from './components/register-quiosque/register-quiosque.component';
import { UploadExamesComponent } from './pages/upload-exames/upload-exames.component';
import { AreaUploadComponent } from './pages/area-upload/area-upload.component';
import { AllCuponsListComponent } from './pages/cupons/all-cupons-list/all-cupons-list.component';

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },

  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'login-quiosque', component: LoginComponent },
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: 'cadastro', component: RegisterComponent },
      { path: 'complete-seu-cadastro', component: RegisterQuiosqueComponent },
      { path: 'upload-de-exames', component: UploadExamesComponent },
      { path: 'area-upload/:id', component: AreaUploadComponent },
      { path: 'quiosque-area', component: QuiosqueAreaComponent },
      { path: 'editar/cadastro', component: RegisterComponent },
      {
        path: 'pergunta',
        component: QuestionComponent,
        data: { animationStation: 'pergunta' },
      },
      { path: 'endereco', component: AddressComponent },
      { path: 'editar/endereco', component: AddressComponent },
      {
        path: 'dados-complementares',
        component: IncomeComponent,
        data: { animationStation: 'dados-complementares' },
      },
      {
        path: 'editar/dados-complementares',
        component: IncomeComponent,
        data: { animationStation: 'dados-complementares' },
      },
      { path: 'minha-conta', component: MyAccountComponent },
      {
        path: 'anamnese',
        component: AnamneseComponent,
        data: { animationStation: 'anamnese' },
      },
      {
        path: 'editar/anamnese',
        component: AnamneseComponent,
        data: { animationStation: 'anamnese' },
      },
      { path: 'area-do-cliente', component: ClientAreaComponent },
      { path: 'pagamento/assinatura/:idAssinatura', component: PaymentComponent },
      { path: 'pagamento/cupom', component: PaymentComponent },
      { path: 'resultados', component: ResultsComponent },
      { path: 'cupons', component: CuponsComponent },
      { path: 'cupons/:id/detalhe', component: DetalheCupomComponent },
      { path: 'cupons/todos-cupons', component: AllCuponsListComponent },
      { path: 'parceiros', component: PartnerComponent },
      { path: 'parceiros/:nomeCategoria/:idCategoria', component: CategoryPartnerComponent },
      {
        path: 'meus-cupons',
        component: MyCouponComponent,
      },
      { path: 'meus-cupons/:id/detalhe', component: DetalheMyCouponComponent },
      { path: 'assinatura', component: SubscriptionComponent },
      { path: 'sobre-nos', component: AboutUsComponent },
      { path: 'categoria', component: CategoryPartnerComponent },
      { path: 'email-redefinir-senha', component: RedefinirSenhaComponent },
      { path: 'redefinir-senha', component: NovaSenhaComponent },
      { path: 'carteirinha', component: CarteirinhaComponent },
      { path: 'carrinho', component: CarrinhoComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
