<div class="col-12 pt-8">
  <div class="backRouter py-4 pt-8 pl-4 cursor-pointer flex">
    <i class="pi pi-chevron-circle-left"> </i>
    <a [routerLink]="['/area-do-cliente']">
      <span>Voltar</span>
    </a>
  </div>
  <section class="voucher">
    <div class="lg:col-11 lg:pt-8 col-12 mx-auto conteudo-principal">
      <div class="grid lg:gap-5 flex-column md:flex-row lg:mx-6 px-3 lg:px-0 justify-content-center">
        @if(cupons === undefined) {
        <div class="col-12 flex justify-content-center text-center">
          <span class="text-xl font-medium text-black-alpha-70 mt-4"
            >Nenhum cupom encontrado</span
          >
        </div>
        } @for (item of cupons; track $index) {
        <div class="col-12 lg:col-3">
          <div
            class="mb-2 md:mb-0 mr-0 md:mr-2 md:pr-5 flex flex-column flex-grow-1"
            [routerLink]="['/meus-cupons/' + item.id + '/detalhe']"
          >
            <div class="p-3 cursor-pointer" [ngClass]="{
              'bg-card-header': item.isActive,
              'bg-card-header-dynamic': !item.isActive || item?.statusCancel == 'Deferido'
            }">
              <div class="flex align-items-center surface-border py-2">
                <div class="">
                  <img
                    [src]="item?.voucher?.category?.urlImage"
                    class="mx-3 my-1"
                    style="width: 60px; height: 60px"
                  />
                </div>
                <div class="flex flex-column align-items-start">
                  <span class="text-xl text-white font-medium mb-1">{{
                    item?.voucher?.partner?.category?.name
                  }}</span>
                  <span
                    class="text-xs text-white font-medium mb-1 text-left"
                    >{{ item?.voucher?.partner?.name }}</span
                  >
                </div>
              </div>
            </div>
            <div
              class="p-4 flex flex-column flex-grow-1 shadow-2 border-round-bottom-3xl shadow-none border-none card-content cursor-pointer"
            >
              <div
                class="font-medium text-base font-light line-height-2 mb-4 pr-1 text-black-alpha-70"
              >
              {{ item?.voucher?.description?.slice(0, 75) }}{{ item?.voucher?.description?.length && item?.voucher?.description?.length! > 75 ? "..." : "" }}
              </div>
              @if(item?.isActive && item?.statusCancel !== 'Deferido') {
              <ul class="list-none p-0 m-0 mb-4 text-bluegray-600">
                <li class="flex align-items-center mb-2">
                  <span class="text-sm font-medium">Valor:&nbsp;</span>
                  <span class="font-bold text-sm">{{
                    formatCurrency(item.voucher?.value)
                  }}</span>
                </li>
                <li class="flex align-items-center">
                  <span class="text-sm font-medium">Válido até:&nbsp;</span>
                  <span class="font-bold text-sm">{{
                    calculateFutureDate(item.voucher?.validity!)
                  }}</span>
                </li>
              </ul>
            }@else if (item.statusCancel == 'Deferido') {
              <div class="mb-4">
                <span class="font-medium">Cupom Cancelado</span>
              </div>
              }@else {
              <div class="mb-4">
                <span class="font-medium">Cupom já utilizado</span>
              </div>
              }
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  </section>
</div>
