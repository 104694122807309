import { ResultadosService } from './../../../services/resultados.service';
import { RouterLink } from '@angular/router';
import { Component } from '@angular/core';
import { LoadingService } from '../../../services/loading.service';
import { BackRouterComponent } from '../../../components/back-router/back-router.component';

@Component({
  selector: 'app-results',
  standalone: true,
  imports: [
    RouterLink,
    BackRouterComponent
  ],
  templateUrl: './results.component.html',
  styleUrl: './results.component.scss'
})
export class ResultsComponent {
  isMobile: boolean = false;
  isDesktop: boolean;
  isAssinante: boolean = JSON.parse(sessionStorage.getItem('subscriptions') ?? '{"active": false}').active;
  resultados: any = [
  ];

  constructor(private resultadosService: ResultadosService, private loadingService: LoadingService){}

  async ngOnInit(){
    this.loadingService.show();
    if (typeof window !== 'undefined') {
      this.checkWidth();
      window.addEventListener('resize', () => {
        this.checkWidth();
      });
    }
    this.resultados = await this.getResults();
    this.loadingService.hide();
  }

  checkWidth() {
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth < 768;
    }
  }

  checkIfMobile(){
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 768;
    this.isDesktop = !this.isMobile;
  }

  handleDownload(item: any){
    if(item.isExam && !this.isAssinante){
      this.loadingService.notify('Aviso', 'Para baixar o exame é necessário ter uma assinatura ativa!');
      return;
    }
    const url = item.urlImage;
    window.open(url, '_blank');
  }


  async getResults() {
    try {
      return await this.resultadosService.getUserExams();
    } catch (error) {
      console.log(error);
      this.loadingService.hide();
    }
  }
}
