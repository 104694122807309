import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getSubscriptions(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/subscriptions/list/0`).toPromise();
  }

  async createPayment(body: any): Promise<any> {
    return await this.http
      .post<any>(`${this.apiUrl}/payment`, body)
      .toPromise();
  }

  async registerCreditCard(token_cartao_credito: string | undefined, body:any): Promise<any> {
    return await this.http
      .post<any>(
        `${this.apiUrl}/subscriptions/client/${token_cartao_credito}`,body)
      .toPromise();
  }

  async createSubPayment(body: any): Promise<any> {
    return await this.http
      .post<any>(`${this.apiUrl}/subscriptions/payment`, body)
      .toPromise();
  }

  
  async createPixPayment(body: any): Promise<any> {
    return await this.http
      .post<any>(`${this.apiUrl}/payment/check/status/{id}`, body)
      .toPromise();
  }
}
