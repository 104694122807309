import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PaymentService } from '../../services/payment.service';
import { DialogModule } from 'primeng/dialog';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { LoadingService } from '../../services/loading.service';
import { CustomerService } from '../../services/customer.service';
import { BackRouterComponent } from '../../components/back-router/back-router.component';

@Component({
  selector: 'app-subscription',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    InputNumberModule,
    FormsModule,
    CommonModule,
    RouterLink,
    RadioButtonModule,
    CheckboxModule,
    DialogModule,
    AvatarModule,
    AvatarGroupModule,
    BackRouterComponent
  ],
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.scss',
})
export class SubscriptionComponent {
  value1: number = 0;
  isMobile: boolean = false;
  isDesktop: boolean;
  arrColors: string[] = ['#ffffff', '#E4E6E7', '#78CDD1'];
  visibleDescrip: boolean = false;
  visibleCancRules: boolean = false;
  VisibleUtilRules: boolean = false;
  selectedCategory: any = {};
  categories: any[] = [];
  isSubscribed: boolean = false;

  constructor(
    private paymentService: PaymentService,
    private loadingService: LoadingService,
    private customerService: CustomerService
  ) {}

  async ngOnInit() {
    this.loadingService.show();
    const sub = await this.getSubs()
    if(sub[0]) {
      this.isSubscribed = true;
    }

    this.categories = await this.getAssinaturas();
    this.selectedCategory = this.categories[0];
    if (typeof window !== 'undefined') {
      this.checkWidth();
      window.addEventListener('resize', () => {
        this.checkWidth();
      });
    }
    this.loadingService.hide();
  }

  checkWidth() {
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth < 992;
    }
  }

  async getAssinaturas() {
    try {
      return await this.paymentService.getSubscriptions();
    } catch (error) {
      console.log(error);
    }
  }

  checkIfMobile() {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 768;
    this.isDesktop = !this.isMobile;
  }

  openDialog(type: number) {
    switch (type) {
      case 0:
        this.visibleDescrip = true;
        break;
      case 1:
        this.VisibleUtilRules = true;
        break;
      case 2:
        this.visibleCancRules = true;
        break;
      default:
        break;
    }
  }

  calculateFutureDate(days: number) {
    const currentDate = new Date();
    const futureDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + days
    );
    const formattedDate = futureDate.toLocaleDateString('pt-BR');
    return formattedDate;
  }

  async getSubs() {
    try {
      return await this.customerService.getSubscriptions();
    } catch (error) {
      this.loadingService.hide();
    }
  }

  formatValue(value: number) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
}
