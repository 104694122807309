<div
  class="flex flex-column align-items-center justify-content-start pt-8"
  [ngClass]="bgImgClass"
  [style.background-color]="getCurrentBgColor()"
>
  @if(isEdit){
  <div class="backRouter p-4 cursor-pointer flex col-12">
    <a [routerLink]="['/minha-conta']">
      <i class="pi pi-chevron-circle-left" style="vertical-align: bottom"> </i>
      <span>Voltar para minha conta</span>
    </a>
  </div>
  }
  <div class="col-12 lg:col-6 mx-auto pt-7 h-screen">
    @for (pair of anamneses?.items; track pair; let i = $index) {
    <div class="mx-auto col-10">
      <div class="pergunta border-1 border-50 px-5 pb-3 pt-0 bg-white">
        <div class="text-title pb-2">
          <h5>{{ pair.itemOrder }} - {{ pair.question }}</h5>
        </div>
        @if(pair?.type !== 4){
        <div class="flex flex-column gap-3">
          @for (category of pair.multipleChoiceTextsArray; track j; let j =
          $index) {
          <div class="field-checkbox">
            <p-radioButton
              [inputId]="category"
              name="category"
              [value]="category"
              [(ngModel)]="selectedItem[i]"
              (ngModelChange)="onRadioChange(pair.question, i)"
            ></p-radioButton>
            <label [for]="category" class="ml-2">{{ category }}</label>
          </div>
          }
        </div>
        <!-- {{selectedItem[i] | json}} -->
        } @else if(pair?.type === 4){
        <div class="scroll">
          <div class="flex flex-wrap class lista-check">
            @for (subArray of pair.multipleChoiceTextsArray; track k; let k =
            $index) {
            <div
              class="flex flex-column pb-0 field-checkbox col-12 align-items-start"
            >
              <div class="field-checkbox mb-0">
                <p-checkbox
                  [(ngModel)]="selectedItem[i]"
                  [label]="subArray"
                  name="group{{ i }}"
                  [value]="subArray"
                  (onChange)="onCheckboxChange(i)"
                  [disabled]="isDisabled(i, subArray)"
                ></p-checkbox>
              </div>
            </div>
            }
          </div>
          <!-- {{selectedItem[i] | json}} -->
        </div>
        }
      </div>
      @if(i === this.verifyItemCount()){
      <div class="flex flex-column-reverse md:flex-row justify-content-center pt-3 gap-3">
        <p-button
          label="Voltar"
          severity="success"
          styleClass="p-button-after"
          icon="pi pi-angle-double-left"
          (click)="goToBack(i)"
        ></p-button>
        @if (currentPage < anamneses?.pages) {
        <p-button
          [label]="isEdit ? 'Continuar e salvar' : 'Continuar'"
          severity="help"
          styleClass="p-button-save "
          iconPos="right"
          icon="pi pi-angle-double-right"
          (click)="manageNextItem(anamneses?.items)"
          [disabled]="validarBotao(selectedItem)"
        ></p-button>

        } @if (currentPage === anamneses?.pages) {
        <p-button
          label="Finalizar"
          severity="help"
          styleClass="p-button-save "
          iconPos="right"
          icon="pi pi-angle-double-right"
          (click)="showDialog(anamneses?.items)"
          [disabled]="!selectedItem"
        ></p-button>
        }
      </div>
      }
    </div>
    }
  </div>
</div>

<!-- Modal de confirmação -->
<p-dialog
  header="Questionário médico concluído com sucesso!"
  [(visible)]="confirmModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
>
  <p class="m-0 mt-3">Pode acessar nossa Área do Cliente.</p>

  <div class="flex flex-column pt-5">
    <div class="col-12">
      <p-button
        label="Acessar"
        (click)="confirmModal = false"
        severity="help"
        styleClass="p-button-save p-button-ok"
        [routerLink]="['/area-do-cliente']"
      ></p-button>
    </div>
  </div>
</p-dialog>
