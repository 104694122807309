import { Component } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Cupom } from '../../../model/product';
import { DialogModule } from 'primeng/dialog';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { LoadingService } from '../../../services/loading.service';
import { MyCouponService } from '../../../services/my-coupon.service';

@Component({
  selector: 'app-detalhe-my-coupon',
  standalone: true,
  imports: [
    ButtonModule,
    CarouselModule,
    RouterLink,
    DialogModule,
    AvatarModule,
    AvatarGroupModule,
    QRCodeModule,
  ],
  templateUrl: './detalhe-my-coupon.component.html',
  styleUrl: './detalhe-my-coupon.component.scss',
})
export class DetalheMyCouponComponent {
  cupon: Cupom[] | undefined = [];
  path: string = 'assets/img/icons/';
  detalheCupom: Cupom | undefined;
  id: string | null | undefined;
  visible: boolean = false;
  confirmModal: boolean = false;
  lastModalConfirm: boolean = false;
  modalSolicitacao: boolean = false;
  cancelButtonClick: boolean = false;
  labelCancelCupom: string = 'Cancelar cupom';
  constructor(
    private myCoupon: MyCouponService,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {}

  async ngOnInit() {
    this.loadingService.show();
    this.id = this.route.snapshot.paramMap.get('id');

    this.detalheCupom = await this.getOneCoupon();
    this.labelCancelCupom = this.verifyCancelRequest(
      this.detalheCupom?.statusCancel
    );

    // this.isCancelRequestSend = localStorage.getItem('isSendCancellationRequestCupon') === 'true';
    this.loadingService.hide();
  }

  openDialog() {
    this.visible = true;
  }

  calculateFutureDate(months: number) {
    const currentDate = new Date();
    const futureDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + months,
      currentDate.getDate()
    );
    const formattedDate = futureDate.toLocaleDateString('pt-BR');
    return formattedDate;
  }

  formatCurrency(value: number) {
    return value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  verifyCancelRequest(statusCancel: string | undefined) {
    if (statusCancel === 'Requerido') {
      this.cancelButtonClick = false;
      return 'Solicitação de cancelamento enviada';
    } else if (statusCancel === 'Deferido') {
      this.cancelButtonClick = false;
      return 'Cupom cancelado';
    } else if (statusCancel === 'Indeferido') {
      this.cancelButtonClick = true;
      return 'Solicitação de cancelamento negada';
    }
    this.cancelButtonClick = true;
    return 'Cancelar cupom';
  }

  async getOneCoupon() {
    try {
      return await this.myCoupon.getOneCupomById(this.id);
    } catch (error) {
      console.log(error);
    }
  }

  createCancelBody() {
    return {
      customerVoucherId: this.detalheCupom?.id,
      customerSubscriptionId: null,
      statusId: '5e97b5d4-3c70-4aea-a16b-3de319ae430b',
      createdAt: new Date(),
      updatedAt: new Date(),
    };
  }

  verifyStatusCancel(statusCancel: string | undefined) {
    if (statusCancel === 'Deferido') {
      return true;
    }else{
      return false;
    }
  }

  async cancelarCupom() {
    try {
      const body = this.createCancelBody();
      const resp = await this.cancelCupom(body);
      if (resp) {
        this.modalSolicitacao = true;
        this.cancelButtonClick = false;
        this.labelCancelCupom = 'Solicitação de cancelamento enviada';
      }
    } catch (error) {}
  }

  cancelar() {
    this.lastModalConfirm = true;
  }

  cancelAllModals() {
    this.confirmModal = false;
    this.lastModalConfirm = false;
  }

  closeAllModals() {
    this.modalSolicitacao = false;
    this.confirmModal = false;
    this.modalSolicitacao = false;
  }

  async cancelCupom(body: any) {
    try {
      return await this.myCoupon.cancelCupomRequest(body);
    } catch (error) {
      this.loadingService.notify(
        'Erro ao realizar a solicitação de cancelamento do cupom.'
      );
    }
  }
}
