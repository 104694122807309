<div class="bg-header py-3 pl-4 pr-4 shadow-2 flex align-items-center justify-content-between fixed w-full z-4">
  @if(!verifyRoute(isLogoOnly) && !verifyRoute(isRegisterStep) || isEdit()) {
  <a [routerLink]="[isQuiosque ? '/quiosque-area' : '/area-do-cliente']" class="imgRedirect">
    <img src="assets/img/icons/icon_topbar.png" alt="Image" height="40" class="mr-0 lg:mr-6" />
  </a>
  }@else if(verifyRoute(isLogoOnly) && !verifyRoute(isRegisterStep) &&
  !isEdit()) {
  <div class="mx-auto">
    <img src="assets/img/icons/logo_topbar.png" alt="" style="max-width: 150px; max-height: 150px" />
  </div>
  @if(!isMobile){
  <span class="text-white" style="position: relative; right: 0px">Versão: 1.0.3</span>
  }@else if(isMobile) {
  <span class="text-white text-center" style="position: relative; right: 0px">Versão:<br />
    1.0.4</span>
  } }@else if(!isEdit()) {
  <img src="assets/img/icons/icon_topbar.png" alt="Image" height="40" class="mr-0 lg:mr-6" />
  } @if (verifyRoute(isRegisterStep) && !isEditar) {
  <h6 class="mx-auto">Passo {{ currentStep }} de 3</h6>
  }@else {
  <span class="text-white lg:font-semibold text-xl">{{ getTitle() }} </span>
  }
  <div class="flex justify-content-between">
    @if((!verifyRoute(isRegisterStep)) && !verifyRoute(isLogoOnly)) {

    @if(!isQuiosque){
    <div class="mr-3">
      <img src="assets/img/icons/cart-shopping-solid.svg" alt="icone-carrinho"
        style="width: 30px; height: 30px; cursor: pointer" [routerLink]="['/carrinho']" />
    </div>
    }
    <a pRipple class="cursor-pointer text-white relative z-2" pStyleClass="@next" enterFromClass="hidden"
      leaveToClass="hidden" [hideOnOutsideClick]="true" (click)="changeIcon()">
      <i [ngClass]="iconActual + ' text-4xl'"></i>
    </a>
    }
    <div
      class="align-items-center flex-grow-1 justify-content-between hidden absolute right-0 top-100 z-1 shadow-2 thumb-section">
      <div class="backGroundBlock" pStyleClass=".thumb-section" enterFromClass="hidden" leaveToClass="hidden"
        (click)="changeIcon()"></div>
      @if (!verifyRoute(isLogoOnly)) {
      <ul class="mx-auto list-none p-0 m-0 flex select-none flex-column">
        @if (currentRouter === '/login' || currentRouter == '/cadastro') {
        <div
          class="align-items-center flex-grow-1 justify-content-end hidden absolute w-full bg-header left-0 top-100 z-1 shadow-2 border-1 border-gray-800">
          <ul class="list-none p-0 m-0 flex select-none flex-column"></ul>
        </div>
        }
      </ul>
      @if(!isQuiosque){
      <ul class="list-none p-0 m-0 flex select-none flex-column surface-border"
        [ngClass]="{ '': !isMobile, 'overflow-y-scroll': isMobile }">
        <div class="list-mobile px-3 pb-6">
          <li>
            <a pRipple
              class="flex p-3 lg:px-3 lg:py-2 align-items-center justify-content-end text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer transition-colors transition-duration-150"
              [routerLink]="['/area-do-cliente']" pStyleClass=".thumb-section" enterFromClass="hidden"
              leaveToClass="hidden" (click)="changeIcon()">
              <span class="border-span">Área do Cliente</span>
            </a>
          </li>
          <li>
            <a pRipple
              class="flex p-3 lg:px-3 lg:py-2 align-items-center justify-content-end text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer transition-colors transition-duration-150"
              [routerLink]="['/cupons']" pStyleClass=".thumb-section" enterFromClass="hidden" leaveToClass="hidden"
              (click)="changeIcon()">
              <span class="border-span">Cupons</span>
            </a>
          </li>

          <li>
            <a pRipple
              class="flex p-3 lg:px-3 lg:py-2 align-items-center justify-content-end text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer transition-colors transition-duration-150"
              [routerLink]="['/resultados']" pStyleClass=".thumb-section" enterFromClass="hidden" leaveToClass="hidden"
              (click)="changeIcon()">
              <span class="border-span">Resultados</span>
            </a>
          </li>

          <li>
            <a pRipple
              class="flex p-3 lg:px-3 lg:py-2 align-items-center justify-content-end text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer transition-colors transition-duration-150"
              [routerLink]="['/parceiros']" pStyleClass=".thumb-section" enterFromClass="hidden" leaveToClass="hidden"
              (click)="changeIcon()">
              <span class="border-span">Parceiros</span>
            </a>
          </li>

          <li>
            <a pRipple
              class="flex p-3 lg:px-3 lg:py-2 align-items-center justify-content-end text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer transition-colors transition-duration-150"
              [routerLink]="['/assinatura']" pStyleClass=".thumb-section" enterFromClass="hidden" leaveToClass="hidden"
              (click)="changeIcon()">
              <span class="border-span">Assinatura</span>
            </a>
          </li>

          <li>
            <a pRipple
              class="flex p-3 lg:px-3 lg:py-2 align-items-center justify-content-end text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer transition-colors transition-duration-150"
              [routerLink]="['/meus-cupons']" pStyleClass=".thumb-section" enterFromClass="hidden" leaveToClass="hidden"
              (click)="changeIcon()">
              <span class="border-span">Meus Cupons</span>
            </a>
          </li>

          <li>
            <a pRipple
              class="flex p-3 lg:px-3 lg:py-2 align-items-center justify-content-end text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer transition-colors transition-duration-150"
              [routerLink]="['/minha-conta']" pStyleClass=".thumb-section" enterFromClass="hidden" leaveToClass="hidden"
              (click)="changeIcon()">
              <span class="border-span">Minha conta</span>
            </a>
          </li>
          @if(isSubscribed){
          <li>
            <a pRipple
              class="flex p-3 lg:px-3 lg:py-2 align-items-center justify-content-end text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer transition-colors transition-duration-150"
              [routerLink]="['/carteirinha']" pStyleClass=".thumb-section" enterFromClass="hidden" leaveToClass="hidden"
              (click)="changeIcon()">
              <span class="border-span">Carteirinha</span>
            </a>
          </li>
          }
          <li>
            <a pRipple
              class="flex p-3 lg:px-3 lg:py-2 align-items-center justify-content-end text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer transition-colors transition-duration-150"
              [routerLink]="['/login']" pStyleClass=".thumb-section" enterFromClass="hidden" leaveToClass="hidden"
              (click)="logout()">
              <span class="border-span">Sair</span>
            </a>
          </li>
        </div>
      </ul>
      } @else {
      <ul class="list-none p-0 m-0 flex select-none flex-column surface-border"
        [ngClass]="{ '': !isMobile, 'overflow-y-scroll': isMobile }">
        <div class="list-mobile px-3 pb-6">
          <li>
            <a pRipple
              class="flex p-3 lg:px-3 lg:py-2 align-items-center justify-content-end text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer transition-colors transition-duration-150"
              [routerLink]="['/quiosque-area']" pStyleClass=".thumb-section" enterFromClass="hidden"
              leaveToClass="hidden" (click)="changeIcon()">
              <span class="border-span">Área do Quiosque</span>
            </a>
          </li>
          <li>
            <a pRipple
              class="flex p-3 lg:px-3 lg:py-2 align-items-center justify-content-end text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer transition-colors transition-duration-150"
              [routerLink]="['/complete-seu-cadastro']" pStyleClass=".thumb-section" enterFromClass="hidden"
              leaveToClass="hidden" (click)="changeIcon()">
              <span class="border-span">Cadastrar Novo Paciente</span>
            </a>
          </li>
          <li>
            <a pRipple
              class="flex p-3 lg:px-3 lg:py-2 align-items-center justify-content-end text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer transition-colors transition-duration-150"
              [routerLink]="['/upload-de-exames']" pStyleClass=".thumb-section" enterFromClass="hidden"
              leaveToClass="hidden" (click)="changeIcon()">
              <span class="border-span">Enviar Exames</span>
            </a>
          </li>
          <li>
            <a pRipple
              class="flex p-3 lg:px-3 lg:py-2 align-items-center justify-content-end text-600 hover:text-900 hover:surface-100 font-medium cursor-pointer transition-colors transition-duration-150"
              [routerLink]="['/login-quiosque']" pStyleClass=".thumb-section" enterFromClass="hidden"
              leaveToClass="hidden" (click)="logout()">
              <span class="border-span">Sair</span>
            </a>
          </li>
        </div>
      </ul>
      }
      }
    </div>
  </div>
</div>