<div class="main pt-8">
  <div class="backRouter p-4 cursor-pointer" [routerLink]="['/area-do-cliente']">
    <i style="text-shadow: 1px 1px 10px #000000;" class="pi pi-chevron-circle-left vertical-align-bottom"> </i>
    <a>
      <span style="text-shadow: 1px 1px 10px #000000;">Voltar</span>
    </a>
  </div>
  <div class="grid col-12 lg:col-12 mx-auto px-4 lg:pt-6 pb-4 lg:justify-content-center lg:align-items-center">

    <div class="md:col-6 lg:col-6" [ngStyle]="{ 'background-color': isMobile ? arrColors[0] : '' }">

      <div class="p-2 text-center border-round result">
        <div class="grid mx-auto justify-content-center">
          <ng-container *ngFor="let item of displayedCategories; let i = index">
            <div class="col-6 flex justify-content-center lg:col-3 lg:col-3 w-fit images" (click)="goToCategory(item)">
              <div
                class="shadow-3 border-circle p-2 pt-4 justify-content-center align-items-center h-100 cursor-pointer flex flex-column">
                <div class="d-flex justify-content-center align-content-start" style="height: 40%">
                  <img [src]="item.urlImage" alt="icone-resultados" style="width: 54px; height: 54px;" />
                </div>
                <div class="d-flex justify-content-start align-items-center overflow-hidden" style="max-width: 111px;">
                  <p class="text-center mt-3 font-bold texto-foto">{{ item.name }}</p>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="col-6 flex justify-content-center lg:col-3 lg:col-3 images w-fit"
            (click)="dialog = true">
            <div
              class="shadow-3 border-circle p-2 pt-4 justify-content-center align-items-center h-100 cursor-pointer flex flex-column">
              <div class="d-flex justify-content-center align-content-start" style="height: 40%">
                <img src="assets/img/icons/icons8_more_200px.png" alt="icone-outros"
                  style="width: 60px; height: 60px;" />
              </div>
              <div class="d-flex justify-content-start align-items-center overflow-hidden" style="max-width: 111px;">
                <p class="text-center mt-3 font-bold texto-foto">Outros</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="md:col-6 bg-cover lg:col-4 bg-mobile-client flex justify-content-end">
    <div class="flex align-items-center" [style]="{ width: '70%' }">
      <div class="w-full relative">
        <p class="text-right font-bold">
          Ainda não é assinante da Estação do Olho?
        </p>
        <p-button label="Assine Agora" [outlined]="true" [rounded]="true" severity="secondary"
          styleClass="p-button-outlined p-button-save w-full lg:w-auto" [routerLink]="['/resultados']"></p-button>
        <p-button label="Assine Agora" [outlined]="true" [rounded]="true" severity="secondary"
          styleClass="p-button-outlined p-button-save w-full lg:w-auto" [routerLink]="['/resultados']"></p-button>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Descubra os Benefícios da Estação do Olho" [(visible)]="dialog" [modal]="true" [draggable]="false"
  [resizable]="false" [breakpoints]="{ '960px': '30%', '640px': '93%' }">
  <div class="flex flex-column p-4">
    <div class="text-justify">
      <p class="text-lg text-black-alpha-90 font-medium line-height-2 mb-3">
        Descubra a Rede de Benefícios Exclusivos da Estação do Olho!
      </p>
      <p class="text-sm text-black-alpha-90 line-height-2 mb-3">
        Você sabia que na Estação do Olho oferecemos uma rede incrível de benefícios? Nossos parceiros incluem os melhores restaurantes, destinos de viagem inesquecíveis, lojas de vestuário da moda e opções de entretenimento para toda a família!
      </p>
      <p class="text-sm text-black-alpha-90 line-height-2 mb-3">
        Imagine desfrutar de descontos especiais em suas refeições favoritas, aproveitar preços exclusivos em suas viagens dos sonhos, renovar seu guarda-roupa com marcas renomadas e ainda curtir momentos de lazer com vantagens únicas! Tudo isso é possível com a nossa rede de parceiros.
      </p>
      <p class="text-sm text-black-alpha-90 line-height-2 mb-3">
        Não perca a chance de transformar sua rotina e aproveitar todas essas vantagens. Assine agora e comece a desfrutar dos benefícios que só a Estação do Olho pode oferecer!
      </p>
      <p class="text-sm text-black-alpha-90 line-height-2 mb-3">
        <a routerLink="/assinatura">Clique aqui para se inscrever e aproveitar</a>
      </p>
      <p class="text-sm text-black-alpha-90 line-height-2 mb-3">
        Já é assinante? Maravilha! Não se esqueça de baixar nosso app para ter acesso a todos os benefícios na palma da sua mão e aproveitar ao máximo todas as vantagens!
      </p>
      <p class="text-sm text-black-alpha-90 line-height-2 mb-3">
        <a href="https://link-to.app/estacao-olho">Baixe o app da Estação do Olho aqui</a>
      </p>
    </div>
  </div>
</p-dialog>

